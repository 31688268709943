<template>
  <div class="testimonials">
    <Slider
      v-if="display.smAndDown.value"
      :blok="{
        ...settings,
        align_items: 'start',
      }"
      class="-mx-2 blog-slider"
      :class="{ 'carousel-buttons-disabled': buttonsDisabled }"
    >
      <slide v-for="(quote, index) in quotes" :key="index" class="px-2">
        <div>
          <WallOfLoveTestimonialCard :quote="quote" />
        </div>
      </slide>
    </Slider>

    <div v-else>
      <div class="testominal-holder">
        <template v-for="(quote, index) in quotes" :key="index">
          <!-- Erste Kachel (mit Hintergrundbild) -->

          <template v-if="false"
            >/template>
            <div class="testimonial-logo-wrap _2">
              [COMPANY LOGO]
              <!-- <img
                src="../images/Trusted-Comany-Logo-3.svg"
                loading="lazy"
                alt="SaaS Webflow Template - Palo Alto - Created by Wedoflow.com and Azwedo.com"
                class="testimonial-logo"
              /> -->
            </div>
            <div class="testimonial-header">
              <div class="testimonial-image-holder">
                <div class="testimonial-info">
                  <div class="testimonial-name">
                    {{ quote.content.author_full_name }}
                  </div>
                  <div class="testimonial-position">
                    {{ quote.content.author_position }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="testimonial-text with-top-space"
              v-html="renderRichText(quote.content.blockquote)"
            ></div>
            <div class="testimonial-big-image">
              <img
                :src="quote.content.portrait.filename"
                loading="lazy"
                alt="SaaS Webflow Template - Palo Alto - Created by Wedoflow.com and Azwedo.com"
                class="testimonial-full-image"
              />
              <div class="testimonial-image-overlay"></div>
            </div>
          </template>

          <!-- Zweite Kachel (ohne Hintergrundbild) -->
          <WallOfLoveTestimonialCard
            :quote="quote"
            :data-animate="!isSafari ? 'zoomIn' : ''"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();
const { isSafari } = useDevice();

defineProps({ blok: Object });
const quotes = ref([]);
const i18n = useI18n();

const settings = computed(() => ({
  itemsToShow: 1,
  itemsToScroll: 1,
  snapAlign: "start",
  navigation: true,
  pagination: false,
  breakpoints: [],
}));

const _blockquotes = useBlockquotes();
async function loadQuotes() {
  quotes.value = await _blockquotes.all(i18n.locale.value);
}

i18n.onLanguageSwitched = async () => {
  await loadQuotes();
};

const handleScroll = () => {
  const testimonialItems = document.querySelectorAll(".testimonial-item");

  const viewportHeight = window.innerHeight;

  testimonialItems.forEach((item) => {
    const rect = item.getBoundingClientRect();
    const itemMidpoint = (rect.top + rect.bottom) / 2;

    if (itemMidpoint < viewportHeight && itemMidpoint > 0) {
      const percentVisible =
        1 -
        Math.abs((itemMidpoint - viewportHeight / 2) / (viewportHeight / 2));

      // Limiting bounds of percentage to be between 0.7 and 1 for opacity and scale
      const opacity = 0.7 + 0.3 * percentVisible;
      const scale = 0.9 + 0.1 * percentVisible;

      item.style.opacity = opacity;
      item.style.transform = `scale3d(${scale}, ${scale}, 1)`;
    } else {
      item.style.opacity = 0.7;
      item.style.transform = "scale3d(0.9, 0.9, 1)";
    }
  });
};

onMounted(() => {
  loadQuotes();
  if (!isSafari) {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call in case some elements are already in view on load
  }
});
</script>

<style scoped lang="scss">
.testominal-holder {
  column-count: 3;
  column-gap: 1em;
  // -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000 20%);
  // mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000 20%);
}

@media screen and (max-width: 991px) {
  .testominal-holder {
    column-count: 2;
  }
}

.item-container {
  overflow: hidden;
}
.item {
  height: 300px;
  transition: height 0.5s ease-in-out; /* Höhentransition */

  &.expanded {
    height: auto;
  }

  &.bg-cover {
    background-size: 100%;
    background-position-y: top;
    background-repeat: no-repeat;
  }
}
blockquote {
  border: none;
  opacity: 0.5;
  padding: 0;
  font-size: smaller;
}
.quote {
  margin-top: 150px;
}
</style>
